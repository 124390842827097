/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Tab, AppBar, Tabs } from '@material-ui/core'
import Title from '../../components/title'
import Course from '../../components/CourseDetail'
import { useParams } from 'react-router-dom'
import Video from '../../components/Video'
import CourseDesc from '../../components/CourseDesc'
import LessonsCourse from '../../components/LessonsCourse'
import CourseComments from '../../components/CourseComments'
import { getImageAddress, getTime, scrollToTop } from '../../config/Utils'
import Moment from 'moment-jalaali'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Helmet } from 'react-helmet'
import Teachers from '../../components/Teachers'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        position: 'relative',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    titr: {
        color: theme.palette.primary.main,
        width: "100%",
        textAlign: 'left',
        marginBottom: 15
    },
    preDiv: {
        padding: 10,
        backgroundColor: "#193d4a",
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hlDiv: {
        padding: 10,
        backgroundColor: "#191d2b",
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: "1px solid #2e344e"
    },
    tabPanel: {
        padding: 20,
        backgroundColor: "#191d2b",
        borderRadius: "0px 0px 10px 10px",
        borderTop: "1px solid #2e344e"
    },
    titleGrid: {
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: 60
        }
    },
    courseSubtitle: {
        width: '100%',
    },
}))
export default function CourseDetailsScreen({ setLoginModalOpen }) {
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [course, setCourse] = useState({})
    const [loading, setLoading] = useState(true)
    let { slug } = useParams()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        scrollToTop()
        let status
        fetch(`https://api.backenddeveloper.ir/api/v1/courses/${slug}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourse(responseJson.data)
                    setLoading(false)
                }
            })

    }, [])
    return loading ? (
        <div className={classes.root} >
            <Helmet>
                <title>آموزش کد نویسی سمت سرور / backend</title>
                <meta name="description" content=" وب سایت BackEndDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی کد نویسی سمت سرور می باشد و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>
            <CircularProgress />
        </div>
    ) : (
            <div className={classes.root} >
                <Helmet>
                    <title>{course.title}</title>
                    <meta name="description" content={course.description} />
                </Helmet>

                <Grid direction="row" container className={classes.titleGrid} >
                    <Title title={course.title} />
                    <Typography variant="subtitle2">
                        {course.subtitle}
                    </Typography>
                    <Course
                        setLoginModalOpen={setLoginModalOpen}
                        course_id={course.id}
                        key={course.id}
                        course_image={getImageAddress(course.course_image)}
                        title={course.title}
                        time={getTime(course.time)}
                        lessonsCount={course.lessons_count}
                        price={course.price}
                        discountPrice={course.price * ((100 - course.discount) / 100)}
                        slug={course.slug}
                        isComplete={course.is_complete}
                        updatedAt={Moment(course.updated_at).format('jD jMMMM jYYYY')}
                    />
                    <Video sourse={course.demo_link} />
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                    <Typography className={classes.titr} variant="h6">
                        قبل از شروع دوره باید بدانید:
                </Typography>
                    {course.prerequisites.split('::').map((pre) =>
                        <Grid key={pre} item xs={12} md={6} lg={3} style={{ padding: 5 }} >
                            <div className={classes.preDiv} >
                                <Typography variant="subtitle2">
                                    {pre}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                    <Typography className={classes.titr} variant="h6">
                        در این دوره چه مطالبی می آموزید؟
                </Typography>
                    {course.headlines.split('::').map((hr) =>
                        <Grid key={hr} item xs={12} md={6} lg={6} style={{ padding: 5 }} >
                            <div className={classes.hlDiv} >
                                <Typography style={{ color: "#FFFFFF99" }} variant="subtitle2">
                                    {hr}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="توضیحات دوره" />
                            <Tab label="جلسات" />
                            <Tab label="نظرات" />
                        </Tabs>
                    </AppBar>
                    <Grid item xs={12} className={classes.tabPanel} >
                        {value === 0 ? (<CourseDesc description={course.description} />) : value === 1 ?
                            (<LessonsCourse slug={slug} topics={course.topics} />) :
                            (<CourseComments course_id={course.id} comments={course.comments} />)}
                    </Grid>
                </Grid>

                <Teachers />
            </div>
        )
}
