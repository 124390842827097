import React from 'react'
import { Route } from 'react-router-dom'
import IndexScreen from '../screens/adminpanel/index'
import Courses from '../screens/adminpanel/Courses'
import AddCourse from '../screens/adminpanel/AddCourse'
import UpdateCourse from '../screens/adminpanel/UpdateCourse'
import Topics from '../screens/adminpanel/Topics'
import Lessons from '../screens/adminpanel/Lessons'
import Discounts from '../screens/adminpanel/Discounts'
import Purchases from '../screens/adminpanel/Purchases'
import Comments from '../screens/adminpanel/Comments'
import Users from '../screens/adminpanel/Users'
import { useRouteMatch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Gallerries from '../screens/adminpanel/Gallerries'
import Notification from '../screens/adminpanel/Notification'

export default function AdminPanel() {
    const { path } = useRouteMatch()
    if (localStorage.token && localStorage.token.length > 0 && localStorage.role === "1") {
        return (
            <>
                <Helmet>
                    <title>پنل مدیریت</title>
                    <meta name="description" content=" وب سایت BackEndDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی کد نویسی سمت سرور می باشد و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
                </Helmet>

                <Route exact path={path}>
                    <IndexScreen />
                </Route>
                <Route exact path={`${path}/courses`}>
                    <Courses />
                </Route>
                <Route exact path={`${path}/courses/add`}>
                    <AddCourse />
                </Route>
                <Route exact path={`${path}/courses/update`}>
                    <UpdateCourse />
                </Route>
                <Route exact path={`${path}/topics`}>
                    <Topics />
                </Route>
                <Route exact path={`${path}/lessons`}>
                    <Lessons />
                </Route>
                <Route exact path={`${path}/discounts`}>
                    <Discounts />
                </Route>
                <Route exact path={`${path}/purchases`}>
                    <Purchases />
                </Route>
                <Route exact path={`${path}/comments`}>
                    <Comments />
                </Route>
                <Route exact path={`${path}/gallery`}>
                    <Gallerries />
                </Route>

                <Route exact path={`${path}/users`}>
                    <Users />
                </Route>
                <Route exact path={`${path}/notifications`}>
                    <Notification />
                </Route>

            </>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }

}
