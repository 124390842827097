import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Title from '../../components/title'
import Course from '../../components/Course'
import Teachers from '../../components/Teachers'
import { getImageAddress, getTime } from '../../config/Utils'
import Moment from 'moment-jalaali'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Helmet } from 'react-helmet'
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        position: 'relative',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },

}))
export default function CoursesScreen() {
    const classes = useStyles()
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                    setLoading(false)
                }
            })
    }, [])
    return (
        <div className={classes.root} >
            <Helmet>
                <title>دوره های آموزشی کد نویسی سمت سرور</title>
                <meta name="description" content=" وب سایت BackEndDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی کد نویسی سمت سرور می باشد و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>

            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Title title="دوره های آموزشی" />
                {loading ? (
                    <CircularProgress />
                ) : (
                        courses.map((course) =>
                            <Course
                                key={course.id}
                                course_image={getImageAddress(course.course_image)}
                                title={course.title}
                                time={getTime(course.time)}
                                lessonsCount={course.lessons_count}
                                price={course.price}
                                discountPrice={course.price * ((100 - course.discount) / 100)}
                                slug={course.slug}
                                isComplete={course.is_complete}
                                updatedAt={Moment(course.updated_at).format('jD jMMMM jYYYY')}
                            />
                        )
                    )}
            </Grid>

            <Teachers />
        </div>
    )
}
