import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Title from '../../components/title'
import Service from '../../components/service'
import Course from '../../components/Course'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import PersonIcon from '@material-ui/icons/Person'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import { useHistory } from 'react-router-dom'
import Teachers from '../../components/Teachers'
import { getImageAddress, getTime } from '../../config/Utils'
import Moment from 'moment-jalaali'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Helmet } from "react-helmet"
import { scrollToTop } from '../../config/Utils'
import { Link } from 'react-router-dom'
import BackendImage from '../../assets/images/backend.png'
import Salary from '../../components/Salary'
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        position: 'relative',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    iconStyle: {
        fontSize: 46,
        color: theme.palette.primary.main
    },
    rootAll: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 30,
    },
    mainAll: {
        cursor: 'pointer',
        position: 'relative',
        height: "100%",
        backgroundColor: "#191d2b",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        // padding: theme.spacing(8),
        // alignItems: 'center',
        transition: ".4s",
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: ".4s"
        }
    },
    mainIntro: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },

}))
export default function HomeScreen() {
    const classes = useStyles()
    let history = useHistory()
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/main-courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                }
                setLoading(false)
            })
    }, [])
    return (
        <div className={classes.root} >
            <Helmet>
                <title>آموزش کد نویسی سمت سرور / backend</title>
                <meta name="description" content=" وب سایت BackEndDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی کد نویسی سمت سرور می باشد و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>
            <Grid container>
                <Grid item xs={12} lg={4}>
                    <img style={{ width: "90%" }} src={BackendImage} alt="backend developer" />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.mainIntro} >
                    <Typography variant="h2" >
                        آموزش Laravel --  Node.js
                    </Typography>
                    <Typography variant="subtitle1" >
                        محبوب ترین  فریمورک های کد نویسی سمت سرور / Backend
                    </Typography>
                    <div style={{ marginTop: 20 }} >
                        <Button
                            component={Link}
                            to="/courses/nodejs"
                            style={{ margin: 10, padding: 10 }} color="primary" variant="outlined" >
                            دوره جامع آموزش Node.js
                        </Button>
                        <Button
                            component={Link}
                            to="/courses/laravel"
                            style={{ margin: 10, padding: 10 }} color="primary" variant="outlined" >
                            دوره جامع آموزش PHP(Laravel)
                        </Button>
                        <Button
                            component={Link}
                            to="/courses"
                            style={{ margin: 5, padding: 10 }} color="primary"  >مشاهده همه دوره ها</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/tutor') }}
                    icon={<PersonIcon className={classes.iconStyle} />}
                    title="درخواست کلاس خصوصی"
                    desc="در صورتی که درخواست کلاس خصوصی (حضوری یا از راه دور/آنلاین) دارید کلیک کنید."
                />
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/request/project') }}
                    icon={<AccountTreeIcon className={classes.iconStyle} />}
                    title="درخواست پروژه"
                    desc="پروژه های خود را به مهندسین توانای آکادمی بسپارید و مدرین ترین، حرفه ای ترین و بهترین وب سایت ها را تحویل بگیرید."
                />
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/courses') }}
                    icon={<VideoLibraryIcon className={classes.iconStyle} />}
                    title="دوره های آنلاین ویدئویی"
                    desc="در این وب سایت دوره های آموزشی آنلاین ویدئویی کد نویسی سمت سرور توسط مجرب ترین اساتید قرا می گیرد."
                />
            </Grid>
            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Title title="دوره های آموزشی" />
                {loading ? (
                    <CircularProgress />
                ) : (
                        <>
                            {courses.map((course) =>
                                <Course
                                    key={course.id}
                                    course_image={getImageAddress(course.course_image)}
                                    title={course.title}
                                    time={getTime(course.time)}
                                    lessonsCount={course.lessons_count}
                                    price={course.price}
                                    discountPrice={course.price * ((100 - course.discount) / 100)}
                                    slug={course.slug}
                                    isComplete={course.is_complete}
                                    updatedAt={Moment(course.updated_at).format('jD jMMMM jYYYY')}
                                />
                            )}
                            <Grid
                                onClick={() => { scrollToTop(); history.push('/courses/') }}
                                item lg={3} md={6} xs={12} className={classes.rootAll} >
                                <Grid className={classes.mainAll} container direction='column' alignItems="center" justify="center" >
                                    <Typography variant="h5" >
                                        مشاهده همه دوره ها
                            </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}

            </Grid>
            <Grid direction="row" container >
                <Salary />
            </Grid>
            <Teachers />
        </div>
    )
}
