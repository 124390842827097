import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#191d2b"
    }
})
export default function Users() {
    const classes = useStyles()
    const [updatedEmail, setUpdatedEmail] = useState('کاربر را از لیست زیر انتخاب کنید')
    const [newPassword, setNewPassword] = useState('')
    const [users, setUsers] = useState([])
    const [user, setUser] = useState({})
    const [course, setCourse] = useState({})
    const [courses, setCourses] = useState([])
    const [updateId, setUpdatedId] = useState(0)
    useEffect(() => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/users', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setUsers(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
        fetch('https://api.backenddeveloper.ir/api/v1/admin/courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/users/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                user_id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setUsers(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const addProccess = () => {
        let status
        fetch("https://api.backenddeveloper.ir/api/v1/admin/users/add-user-course", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': "application/json"
            },
            body: JSON.stringify({
                user_id: user.id,
                course_id: course.id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 201) {
                    setUpdatedEmail('با موفقیت ذخیره شد')
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const changePassword = () => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/users/change-password', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: updateId,
                password: newPassword
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setUpdatedEmail('با موفقیت انجام شد')
                    setNewPassword('')
                    setUpdatedId(0)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }
    return (
        <Grid container className={classes.root} >
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={users}
                    getOptionLabel={option => {
                        return option.email ? option.email : ""

                    }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب کاربر" variant="outlined" />}
                    onChange={(e, t) => {
                        setUser(t)
                    }}
                    value={user}
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={courses}
                    getOptionLabel={option => {
                        return option.title ? option.title : ""

                    }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب دوره" variant="outlined" />}
                    onChange={(e, t) => {
                        setCourse(t)
                    }}
                    value={course}
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <Button variant="outlined" fullWidth onClick={addProccess} style={{ height: 55 }} >
                    اضافه کردن کاربر به دوره
                </Button>
            </Grid>

            <Grid item xs={4} style={{
                marginBottom: 25, padding: 10, justifyContent: 'center',
                alignItems: 'center', display: 'flex'
            }} >
                {updatedEmail}
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <TextField
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    variant="outlined" fullWidth
                    label="رمز عبور"
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <Button variant="outlined" fullWidth onClick={changePassword} style={{ height: 55 }} >
                    تغییر رمز عبور
                </Button>
            </Grid>

            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>نام</TableCell>
                            <TableCell align="left">ایمیل</TableCell>
                            <TableCell align="left">موبایل</TableCell>
                            <TableCell align="left">شهر</TableCell>
                            <TableCell align="left">تاریخ ثبت نام</TableCell>
                            <TableCell align="left">تغییر رمز عبور</TableCell>
                            <TableCell align="left">حذف</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >{row.name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.mobile}</TableCell>
                                <TableCell align="left">{row.city}</TableCell>
                                <TableCell align="left">{row.created_at}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => {
                                            setUpdatedId(row.id)
                                            setUpdatedEmail(row.email)
                                        }}
                                        variant="text" color="primary" >
                                        تغییر رمز عبور
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
