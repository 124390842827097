import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#191d2b"
    }
})
export default function Topics() {
    const classes = useStyles()
    const [courses, setCourses] = useState([])
    const [topics, setTopics] = useState([])
    const [course, setCourse] = useState({})
    const [type, setType] = useState(0)
    const [newTopic, setNewTopic] = useState('')
    const [updateId, setUpdatedId] = useState(0)
    useEffect(() => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
        fetch('https://api.backenddeveloper.ir/api/v1/admin/topics', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setTopics(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/topics/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setTopics(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const addProccess = () => {
        const url = type === 0 ? "https://api.backenddeveloper.ir/api/v1/admin/topics/add" : "https://api.backenddeveloper.ir/api/v1/admin/topics/update"
        let data = type === 0 ? {
            title: newTopic,
            course_id: course.id
        } : {
                title: newTopic,
                course_id: course.id,
                id: updateId
            }
        let status
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': "application/json"
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 201) {
                    setTopics(responseJson.data)
                    setNewTopic('')
                    setCourse({})
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    return (
        <Grid container className={classes.root} >
            <Grid item xs={4} style={{ marginBottom: 25 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={courses}
                    getOptionLabel={option => {
                        return option.title ? option.title : ""

                    }}
                    style={{ width: 300 }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب دوره" variant="outlined" />}
                    onChange={(e, t) => {
                        setCourse(t)
                    }}
                    value={course}
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25 }} >
                <TextField
                    value={newTopic}
                    onChange={(e) => setNewTopic(e.target.value)}
                    variant="outlined" fullWidth
                    label="عنوان تاپیک"
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25 }} >
                <Button variant="outlined" fullWidth onClick={addProccess} >
                    اضافه / ویرایش
                </Button>
            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>عنوان تاپیک</TableCell>
                            <TableCell align="left">دوره</TableCell>
                            <TableCell align="left">تعداد درس</TableCell>
                            <TableCell align="left">زمان</TableCell>
                            <TableCell align="left">ویرایش</TableCell>
                            <TableCell align="left">حذف</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topics.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >
                                    {row.title}
                                </TableCell>
                                <TableCell align="left">{row.course ? row.course.title : ""}</TableCell>
                                <TableCell align="left">{row.lessons_count}</TableCell>
                                <TableCell align="left">{row.time}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => {
                                            setUpdatedId(row.id)
                                            setCourse(row.course)
                                            setNewTopic(row.title)
                                            setType(1)
                                        }}
                                        variant="text" color="primary" >
                                        ویرایش
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
