import React, { useState, useEffect } from 'react'
import {
  Container,
  TextField,
  Grid,
  Button,
  Slide,
  Modal,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { validateEmail } from '../config/Utils'
import Apis from '../config/Apis'
import SuccessMessage from '../components/SuccessMessage'
import ErrorMessage from '../components/ErrorMessage'
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    padding: 20,
    paddingBottom: 20,
    backgroundColor: '#FFF',
    borderRadius: 10,
    position: 'relative',
    boxShadow: `0px 0px 20px  5px ${theme.palette.primary.main}`,
    width: 800,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      padding: 5,
      paddingTop: 10,
      maxHeight: '95%',
      overflowY: 'scroll',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  iconSpan: {
    width: 80,
    height: 80,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    borderRadius: 50,
    top: -50,
  },
  icon: {
    fontSize: 60,
    color: '#FFF',
  },
  headerModalText: {
    color: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
  },
  spinnerBox: {
    width: 0,
    height: 0,
    overflow: 'Hidden',
    transition: 'all 0.3s ease-in-out',
  },
  spinnerBoxVisible: {
    width: 35,
    height: 20,
  },
}))
export default function RegisterModal({
  resgisterModalOpen,
  setRegisterModalOpen,
  setLoginModalOpen,
}) {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [mobile, setMobile] = useState('')
  const [mobileError, setMobileError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [regiterButtonDisabled, setRegisterButtonDisabled] = useState(true)
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false)
  const [openErrorMessage, setOpenErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (
      name.length > 0 &&
      /^09[0-9]{9}$/.test(mobile) &&
      validateEmail(email) &&
      city.length > 0 &&
      password.length > 5 &&
      password === repeatPassword
    ) {
      setRegisterButtonDisabled(false)
    } else {
      setRegisterButtonDisabled(true)
    }
  }, [name, mobile, email, city, password, repeatPassword])
  const register = () => {
    setLoading(true)
    let formData = {
      name,
      mobile,
      city,
      email,
      password,
    }
    let status
    fetch(Apis.regitser, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        status = response.status
        return response.json()
      })
      .then((responseJson) => {
        setLoading(false)
        if (status === 400) {
          for (const key in responseJson.errors) {
            if (responseJson.errors.hasOwnProperty(key)) {
              switch (key) {
                case 'name':
                  setNameError(responseJson.errors[key][0])
                  break

                case 'mobile':
                  setMobileError(responseJson.errors[key][0])
                  break

                case 'email':
                  setEmailError(responseJson.errors[key][0])
                  break

                case 'city':
                  setCityError(responseJson.errors[key][0])
                  break

                case 'password':
                  setPasswordError(responseJson.errors[key][0])
                  break

                default:
                  break
              }
            }
          }
        } else if (status === 201) {
          localStorage.token = responseJson.token
          localStorage.token_type = responseJson.token_type
          setOpenSuccessMessage(true)
          setTimeout(() => {
            window.location.reload()
          }, 1)
        } else {
          setOpenErrorMessage(true)
        }
      })
  }
  return (
    <>
      <Modal
        open={resgisterModalOpen}
        onClose={() => setRegisterModalOpen(false)}
      >
        <Slide
          direction='up'
          in={resgisterModalOpen}
          mountOnEnter
          unmountOnExit
        >
          <Container className={classes.root}>
            <Grid
              item
              container
              direction='row'
              justify='center'
              alignItems='center'
              xs={12}
            >
              <Typography variant='h3' className={classes.headerModalText}>
                ثبت نام{' '}
              </Typography>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 20 }}
                  required
                  label='نام و نام خانوادگی'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setNameError('')
                    },
                    onBlur: () => {
                      name.length === 0
                        ? setNameError('نام حتما باید وارد شود')
                        : setNameError('')
                    },
                  }}
                  error={nameError.length > 0}
                  helperText={nameError}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 15 }}
                  required
                  label='تلفن همراه'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setMobileError('')
                    },
                    onBlur: () => {
                      /^09[0-9]{9}$/.test(mobile)
                        ? setMobileError('')
                        : setMobileError(
                          'فرمت شماره موبایل وارد شده صحیح نمی باشد',
                        )
                    },
                  }}
                  error={mobileError.length > 0}
                  helperText={mobileError}
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 15 }}
                  required
                  label='ایمیل'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setEmailError('')
                    },
                    onBlur: () => {
                      validateEmail(email)
                        ? setEmailError('')
                        : setEmailError('فرمت ایمیل وارد شده صحیح نمی باشد')
                    },
                  }}
                  error={emailError.length > 0}
                  helperText={emailError}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 15 }}
                  required
                  label='شهر'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setCityError('')
                    },
                    onBlur: () => {
                      city.length === 0
                        ? setCityError('شهر حتما باید وارد شود')
                        : setCityError('')
                    },
                  }}
                  error={cityError.length > 0}
                  helperText={cityError}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 15 }}
                  required
                  type='password'
                  label='رمز عبور'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setPasswordError('')
                    },
                    onBlur: () => {
                      password.length < 6
                        ? setPasswordError('رمز عبور باید حداقل 6 کاراکتر باشد')
                        : setPasswordError('')
                    },
                  }}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '95%', marginTop: 15 }}
                  required
                  type='password'
                  label='تکرار رمز عبور'
                  variant='outlined'
                  inputProps={{
                    className: 'blackinput',
                    onFocus: () => {
                      setRepeatPasswordError('')
                    },
                    onBlur: () => {
                      repeatPassword !== password
                        ? setRepeatPasswordError(
                          'رمز عبور با تکرار آن مطابقت ندارد',
                        )
                        : setRepeatPasswordError('')
                    },
                  }}
                  error={repeatPasswordError.length > 0}
                  helperText={repeatPasswordError}
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </Grid>
              <Button
                onClick={register}
                disabled={regiterButtonDisabled || loading}
                size='large'
                style={{ width: '95%', marginTop: 25 }}
                variant='contained'
                color='primary'
              >
                ثبت نام
                <div
                  className={[
                    classes.spinnerBox,
                    loading ? classes.spinnerBoxVisible : '',
                  ].join(' ')}
                >
                  {' '}
                  &nbsp;&nbsp; <CircularProgress size={20} />
                </div>
              </Button>
              <Button
                onClick={() => {
                  setRegisterModalOpen(false, setLoginModalOpen(true))
                }}
                size='large'
                style={{
                  width: '95%',
                  marginTop: 10,
                  justifyContent: 'flex-start',
                }}
                variant='text'
                color='primary'
              >
                صفحه ورود
              </Button>
            </Grid>
          </Container>
        </Slide>
      </Modal>
      <SuccessMessage
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
        message='ثبت نام شما با موفقیت انجام شد'
      />
      <ErrorMessage
        open={openErrorMessage}
        setOpen={setOpenErrorMessage}
        message='خطایی در ارتباط با سرور رخ داده است. دوباره تلاش کنید'
      />
    </>
  )
}
