export default {
    primary: {
        main: "#d38731",
        contrastText: "#FFF",
        backgroundColor: "#191d2b"
    },
    secondary: {
        main: "#a4acc4"
    },
    text: {
        primary: "#FFF",
        secondary: "#a4acc4"
    }
}