/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorMessage from './ErrorMessage'
import { separate } from '../config/Utils'
import { BASE_URL } from '../config/Apis'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 30,
  },
  main: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#1b1c23',
    zIndex: 1,
    border: '1px solid #2e344e',
    borderTop: '5px solid #2e344e',
    // padding: theme.spacing(8),
    // alignItems: 'center',
    transition: '.4s',
    '&:hover': {
      borderTopColor: theme.palette.primary.main,
      transition: '.4s',
    },
  },

  title: {
    position: 'relative',
    paddingBottom: 10,
    marginBottom: 5,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 'auto',
      bottom: 0,
      height: 2,
      width: 50,
      background: ' #2e344e',
    },
  },
  desc: {
    marginTop: 5,
    textAlign: 'left',
  },
  topImage: {
    width: '100%',
  },
  tag: {
    backgroundColor: '#10c98d',
    position: 'absolute',
    right: 0,
    padding: 5,
    color: '#fff',
  },
  priceGrid: {
    background: ' #193d4a',
    borderRadius: 10,
  },
}))

export default function CourseDetails({
  course_id,
  title,
  time,
  course_image,
  lessonsCount,
  price,
  discountPrice,
  slug,
  isComplete,
  updatedAt,
  setLoginModalOpen,
}) {
  const classes = useStyles()
  const [priceState, setPriceState] = useState(discountPrice)
  const [discount, setDiscount] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [payLoading, setPayLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [userHasCourse, setUserHasCourse] = useState(false)
  useEffect(() => {
    let status
    fetch(`${BASE_URL}user-has-course`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: localStorage.token_type + ' ' + localStorage.token,
      },
      body: JSON.stringify({
        course_id: course_id,
      }),
    })
      .then((response) => {
        status = response.status
        return response.json()
      })
      .then((responseJson) => {
        if (responseJson.error && responseJson.type) {
          localStorage.clear()
        } else if (responseJson.error && !responseJson.type) {
          setUserHasCourse(false)
        } else if (status === 200) {
          setUserHasCourse(true)
        } else {
          setUserHasCourse(false)
        }
      })
  }, [])
  const onDiscountClick = () => {
    if (!loading) {
      setLoading(true)
      let status
      fetch(`${BASE_URL}discount`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          text: discount,
        }),
      })
        .then((response) => {
          status = response.status
          return response.json()
        })
        .then((responseJson) => {
          if (status === 200) {
            setPriceState()
            setPriceState(discountPrice * ((100 - responseJson.data) / 100))
          } else if (status === 404) {
            setError('کد تخفیف معتبر نمی باشد')
          } else {
            setError('در ارتباط با سرور مشکلی پیش آمده است')
          }
          setLoading(false)
        })
    }
  }
  const pay = () => {
    setOpen(false)
    if (!userHasCourse && localStorage.token && localStorage.token.length > 0) {
      if (!payLoading) {
        setPayLoading(true)
        let status
        fetch(`${BASE_URL}pay`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'content-type': 'application/json',
            Authorization: localStorage.token_type + ' ' + localStorage.token,
          },
          body: JSON.stringify({
            course_id: course_id,
            discount: discount,
            callbackUrl: 'https://backenddeveloper.ir/pay/check/',
          }),
        })
          .then((response) => {
            status = response.status
            return response.json()
          })
          .then((responseJson) => {
            if (status === 200) {
              window.location = responseJson.data
            } else {
              setOpen(true)
            }
          })
      }
    } else if (userHasCourse) {
    } else {
      setLoginModalOpen(true)
    }
  }
  return (
    <Grid item lg={4} md={6} xs={12} className={classes.root}>
      <Grid
        className={classes.main}
        container
        direction='column'
        alignItems='flex-start'
      >
        <img src={course_image} alt={title} className={classes.topImage} />
        <Grid
          container
          style={{ padding: 10, borderBottom: '1px solid #2e344e' }}
        >
          <Typography className={classes.title} variant='h5'>
            {title}
          </Typography>
          <Grid item xs={12} lg={8} container direction='column'>
            <Typography className={classes.desc} variant='caption'>
              طول دوره : {time} ساعت
            </Typography>
            <Typography className={classes.desc} variant='caption'>
              تعداد جلسات : {lessonsCount}
            </Typography>
            <Typography className={classes.desc} variant='caption'>
              آخرین به روز رسانی: {updatedAt}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            container
            direction='column'
            justify='center'
            alignItems='center'
            className={classes.priceGrid}
          >
            <Typography
              variant='overline'
              style={{ textDecoration: 'line-through' }}
            >
              {separate(price)} تومان
            </Typography>
            <Typography variant='body2'>
              {separate(priceState)} تومان
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ padding: 10 }} direction='row'>
          <TextField
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            label='کد تخفیف'
            size='small'
            variant='outlined'
            style={{ width: 210 }}
            error={error.length > 0}
            helperText={error}
          />
          <Button
            onClick={onDiscountClick}
            style={{ marginRight: 10 }}
            color='primary'
          >
            {loading ? 'در حال اعمال' : 'ثبت کد تخفیف'}
          </Button>
        </Grid>
        <Grid container style={{ padding: 10 }}>
          <Button
            size='large'
            fullWidth
            onClick={pay}
            color='primary'
            variant='outlined'
          >
            {payLoading ? (
              <CircularProgress
                style={{ marginRight: 10, marginLeft: 10 }}
                size={20}
              />
            ) : null}
            {userHasCourse
              ? 'شما در این دوره ثبت نام کرده اید'
              : localStorage.token && localStorage.token.length > 0
                ? 'خرید و ثبت نام دوره'
                : 'ورود / ثبت نام'}
          </Button>
        </Grid>
        <span className={classes.tag}>
          {isComplete ? 'دوره کامل شده' : 'درحال برگزاری'}
        </span>
      </Grid>
      <ErrorMessage
        open={open}
        setOpen={setOpen}
        message='در ارتباط با سرور مشکلی پیش آمده است.دوباره تلاش کنید'
      />
    </Grid>
  )
}
