import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import canada from '../assets/images/a1.jpg'
import iran1 from '../assets/images/a4.jpg'
import iran2 from '../assets/images/a3.jpg'
import iran3 from '../assets/images/a9.jpg'
import Title from './title'
const styles = theme => ({
    rootPaper: {
        padding: 10,
        marginTop: 30,
        background: 'transparent',
    },
    title: {
        margin: '23px 0 15px 0',
    },
    textBody: {
        marginBottom: 5,
        textAlign: 'justify',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 2,
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    logos: {
        width: '100%',
    },
    imgsalary: {
        width: "95%"
    },
    imgsalaryIran: {
        width: "95%"
    }
})
const Salary = (props) => {
    const { classes } = props
    return (
        <Paper square className={classes.rootPaper}>
            <Grid container >
                <Title title="میزان حقوق برنامه نویسان Backend" />
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.textBody}>
                        یکی از مهمترین بخش های وب سایت ها، بازی ها و اپلیکیشن ها کد نویسی سمت سرور آن ها می باشد. کد نویسی سمت سرور که اصطلاحا به آن BackEnd می گویند در حال حاضر جزء جدایی ناپذیر برنامه ها و وب سایت هاست. کد نویسی سمت سرور مهمترین بخش وب سایت ها و اپلیکیشن های موبایل است. به همین دلیل کسانی که کد نویسی سمت سرور انجام می دهند میانگین حقوق بالایی دارند. کسانی که وارد این حوزه شوند به راحتی می توانند جذب شرکت های مختلف شوند یا به کمک طراحان فرانت اند پروژه انجام دهند یا ایده های خودشان را پیاده سازی کنند.
                    </Typography>
                    <Typography variant="body1" className={classes.textBody}>
                        زبان PHP و فریمورک لاراول و زبان جاوا اسکرپت و فریمورک Node.js در حال حاضر  بازار کار بسیار خوبی در ایران و در جهان دارند.
                        در زیر چند آگهی از حقوق برنامه نویسان بک اند در یک وب سایت معتبر کاریابی در ایران قرار داده شده است.
                    </Typography>
                    <img src={canada} className={classes.imgsalary} alt="آموزش React js" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={iran1} className={classes.imgsalaryIran} alt="آموزش React js" />
                    <img src={iran2} className={classes.imgsalaryIran} alt="آموزش React js" />
                    <img src={iran3} className={classes.imgsalaryIran} alt="آموزش React js" />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default withStyles(styles)(Salary)