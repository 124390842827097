import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative', marginBottom: 20, width: "100%", paddingBottom: 20
    },
    title: {
        textAlign: 'left',
        "&::after": {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 'auto',
            bottom: 0,
            height: 5,
            borderRadius: 100,
            width: 35,
            background: ' #d38731',
        },
        "&::before": {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 'auto',
            bottom: 0,
            height: 5,
            borderRadius: 100,
            width: 100,
            background: "rgba(211, 135, 49, .3)"
        }
    },
    subtitle: {
        fontSize: '1.7rem',
        position: 'absolute',
        left: 15,
        top: '70%',
        lineHeight: "1rem",
        fontWeight: 900,
        color: 'rgba(89, 97, 127, 0.16)',
        display: 'inline-block',
        zIndex: -1,
        transform: ' translateY(-40%)',
    }
}))
export default function Title({ title }) {
    const classes = useStyles()
    return (
        <div className={classes.root}  >
            <Typography className={classes.title} variant="h4" >{title}</Typography>
            <span className={classes.subtitle} >{title}</span>
        </div>
    )
}
