import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LogoImage from "../assets/images/logo.png";
import "./index.css";
import { Switch, useLocation, useHistory } from "react-router-dom";
import MainRoutes from "../Routes/index";
import UserRoutes from "../Routes/userPanel";
import AdminRoutes from "../Routes/adminPanel";
import LoginModal from "../components/LoginModal";
import RegisterModal from "../components/RegisterModal";
import { scrollToTop } from "../config/Utils";
import { Link } from "react-router-dom";
import NotificationModal from "../components/NotificationModal";
import TelegramChanall from "../components/TelegramChanall";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    margin: theme.spacing(2),
    marginLeft: 0,
    widt: 50,
    height: 50,
    backgroundColor: theme.palette.primary.backgroundColor,
    borderRadius: 0,
    border: "1px solid #2e344e",
    position: "fixed",
    top: 20,
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  topDrawer: {
    width: "100%",
    padding: 20,
    borderBottom: "1px solid #2e344e",
    textAlign: "center",
  },
  profileImage: {
    width: 200,
    maxWidth: "100%",
    verticalAlign: "middle",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#191d2b",
    height: "100vh",
    borderRight: "1px solid #2e344e",
  },
  centerDrawer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
  },
  bottomDrawer: {
    padding: 15,
    borderTop: "1px solid #2e344e",
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    position: "relative",
  },
  desktopHeaderDiv: {
    width: "100%",
    height: 50,
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 30,
    paddingLeft: 30,
  },
  leftDesktopMenu: {},
  activeMenu: {
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.main,
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [resgisterModalOpen, setRegisterModalOpen] = useState(false);
  let location = useLocation();
  let history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <div className={classes.topDrawer}>
        <img
          src={LogoImage}
          alt='backenddeveloper.ir'
          className={classes.profileImage}
        />
      </div>
      <div className={classes.centerDrawer}>
        <List style={{ width: "100%" }}>
          <ListItem
            className={location.pathname === "/" ? null : "listItem"}
            style={{
              backgroundColor:
                location.pathname === "/" ? "#d38731" : "transparent",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              scrollToTop();
              history.push("/");
              setMobileOpen(false);
            }}
            button
          >
            <ListItemText
              disableTypography={true}
              children={
                <Typography
                  className='listItemText'
                  style={{
                    color: location.pathname === "/" ? "#fff" : "#a4acc4",
                  }}
                  variant='body2'
                >
                  صفحه اصلی
                </Typography>
              }
            />
            <div className='overlay' />
          </ListItem>
          {localStorage.role && localStorage.role === "1" ? (
            <ListItem
              className={
                /^\/admin-panel/i.test(location.pathname) ? null : "listItem"
              }
              style={{
                backgroundColor: /^\/admin-panel/i.test(location.pathname)
                  ? "#d38731"
                  : "transparent",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onClick={() => {
                scrollToTop();
                history.push("/admin-panel");
                setMobileOpen(false);
              }}
              button
            >
              <ListItemText
                disableTypography={true}
                children={
                  <Typography
                    className='listItemText'
                    style={{
                      color: /^\/admin-panel/i.test(location.pathname)
                        ? "#fff"
                        : "#a4acc4",
                    }}
                    variant='body2'
                  >
                    مدیریت
                  </Typography>
                }
              />
              <div className='overlay' />
            </ListItem>
          ) : null}

          <ListItem
            className={
              /^\/courses/i.test(location.pathname) ? null : "listItem"
            }
            style={{
              backgroundColor: /^\/courses/i.test(location.pathname)
                ? "#d38731"
                : "transparent",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              scrollToTop();
              history.push("/courses");
              setMobileOpen(false);
            }}
            button
          >
            <ListItemText
              disableTypography={true}
              children={
                <Typography
                  className='listItemText'
                  style={{
                    color: /^\/courses/i.test(location.pathname)
                      ? "#fff"
                      : "#a4acc4",
                  }}
                  variant='body2'
                >
                  دوره های آموزشی
                </Typography>
              }
            />
            <div className='overlay' />
          </ListItem>

          {localStorage.token && localStorage.token.length > 0 ? (
            <ListItem
              className={
                /^\/panel\/courses/i.test(location.pathname) ? null : "listItem"
              }
              style={{
                backgroundColor: /^\/panel\/courses/i.test(location.pathname)
                  ? "#d38731"
                  : "transparent",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onClick={() => {
                scrollToTop();
                history.push("/panel/courses");
                setMobileOpen(false);
              }}
              button
            >
              <ListItemText
                disableTypography={true}
                children={
                  <Typography
                    className='listItemText'
                    style={{
                      color: /^\/panel\/courses/i.test(location.pathname)
                        ? "#fff"
                        : "#a4acc4",
                    }}
                    variant='body2'
                  >
                    دوره های خریداری شده
                  </Typography>
                }
              />
              <div className='overlay' />
            </ListItem>
          ) : null}

          <ListItem
            className={
              /^\/request\/project/i.test(location.pathname) ? null : "listItem"
            }
            style={{
              backgroundColor: /^\/request\/project/i.test(location.pathname)
                ? "#d38731"
                : "transparent",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              scrollToTop();
              history.push("/request/project");
              setMobileOpen(false);
            }}
            button
          >
            <ListItemText
              disableTypography={true}
              children={
                <Typography
                  className='listItemText'
                  style={{
                    color: /^\/request\/project/i.test(location.pathname)
                      ? "#fff"
                      : "#a4acc4",
                  }}
                  variant='body2'
                >
                  درخواست پروژه
                </Typography>
              }
            />
            <div className='overlay' />
          </ListItem>
          <ListItem
            className={/^\/tutor/i.test(location.pathname) ? null : "listItem"}
            style={{
              backgroundColor: /^\/tutor/i.test(location.pathname)
                ? "#d38731"
                : "transparent",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              scrollToTop();
              history.push("/tutor");
              setMobileOpen(false);
            }}
            button
          >
            <ListItemText
              disableTypography={true}
              children={
                <Typography
                  className='listItemText'
                  style={{
                    color: /^\/tutor/i.test(location.pathname)
                      ? "#fff"
                      : "#a4acc4",
                  }}
                  variant='body2'
                >
                  درخواست کلاس خصوصی
                </Typography>
              }
            />
            <div className='overlay' />
          </ListItem>

          <ListItem
            className={
              /^\/contact/i.test(location.pathname) ? null : "listItem"
            }
            style={{
              backgroundColor: /^\/contact/i.test(location.pathname)
                ? "#d38731"
                : "transparent",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              scrollToTop();
              history.push("/contact");
              setMobileOpen(false);
            }}
            button
          >
            <ListItemText
              disableTypography={true}
              children={
                <Typography
                  className='listItemText'
                  style={{
                    color: /^\/contact/i.test(location.pathname)
                      ? "#fff"
                      : "#a4acc4",
                  }}
                  variant='body2'
                >
                  تماس با ما
                </Typography>
              }
            />
            <div className='overlay' />
          </ListItem>
        </List>
      </div>
      {localStorage.token && localStorage.token.length > 0 ? (
        <div className={classes.bottomDrawer}>
          {/* <Button onClick={() => { setLoginModalOpen(true); setMobileOpen(false) }} >تغییر رمز عبور</Button>
                    {'/'} */}
          <Button
            onClick={() => {
              localStorage.clear();
              setMobileOpen(false);
              window.location.reload();
            }}
          >
            خروج
          </Button>
        </div>
      ) : (
        <div className={classes.bottomDrawer}>
          <Button
            onClick={() => {
              setLoginModalOpen(true);
              setMobileOpen(false);
            }}
          >
            ورود
          </Button>
          {"/"}
          <Button
            onClick={() => {
              setRegisterModalOpen(true);
              setMobileOpen(false);
            }}
          >
            ثبت نام
          </Button>
        </div>
      )}
    </>
  );

  const desktopHeader = (
    <div className={classes.desktopHeaderDiv}>
      <div>
        <Button
          color={location.pathname === "/" ? "primary" : "default"}
          className={location.pathname === "/" ? classes.activeMenu : null}
          component={Link}
          to='/'
        >
          صفحه اصلی
        </Button>
        {localStorage.role && localStorage.role === "1" ? (
          <Button
            color={
              /^\/admin-panel/i.test(location.pathname) ? "primary" : "default"
            }
            className={
              /^\/admin-panel/i.test(location.pathname)
                ? classes.activeMenu
                : null
            }
            component={Link}
            to='/admin-panel'
          >
            مدیریت
          </Button>
        ) : null}
        <Button
          color={/^\/courses/i.test(location.pathname) ? "primary" : "default"}
          className={
            /^\/courses/i.test(location.pathname) ? classes.activeMenu : null
          }
          component={Link}
          to='/courses'
        >
          دوره های آموزشی
        </Button>
        {localStorage.token && localStorage.token.length > 0 ? (
          <Button
            color={
              /^\/panel\/courses/i.test(location.pathname)
                ? "primary"
                : "default"
            }
            className={
              /^\/panel\/courses/i.test(location.pathname)
                ? classes.activeMenu
                : null
            }
            component={Link}
            to='/panel/courses'
          >
            دوره های خریداری شده
          </Button>
        ) : null}
        <Button
          color={
            /^\/request\/project/i.test(location.pathname)
              ? "primary"
              : "default"
          }
          className={
            /^\/request\/project/i.test(location.pathname)
              ? classes.activeMenu
              : null
          }
          component={Link}
          to='/request/project'
        >
          درخواست پروژه
        </Button>
        <Button
          color={/^\/tutor/i.test(location.pathname) ? "primary" : "default"}
          className={
            /^\/tutor/i.test(location.pathname) ? classes.activeMenu : null
          }
          component={Link}
          to='/tutor'
        >
          درخواست کلاس خصوصی
        </Button>
        <Button
          color={/^\/contact/i.test(location.pathname) ? "primary" : "default"}
          className={
            /^\/contact/i.test(location.pathname) ? classes.activeMenu : null
          }
          component={Link}
          to='/contact'
        >
          تماس با ما
        </Button>
      </div>
      <div>
        {localStorage.token && localStorage.token.length > 0 ? (
          <div className={classes.leftDesktopMenu}>
            {/* <Button onClick={() => { setLoginModalOpen(true); setMobileOpen(false) }} >تغییر رمز عبور</Button>
                    {'/'} */}
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                localStorage.clear();
                setMobileOpen(false);
                window.location.reload();
              }}
            >
              خروج
            </Button>
          </div>
        ) : (
          <div className={classes.leftDesktopMenu}>
            <Button
              color='primary'
              variant='text'
              onClick={() => {
                setLoginModalOpen(true);
                setMobileOpen(false);
              }}
            >
              ورود
            </Button>
            {"/"}
            <Button
              color='secondary'
              variant='text'
              onClick={() => {
                setRegisterModalOpen(true);
                setMobileOpen(false);
              }}
            >
              ثبت نام
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <IconButton
        color='inherit'
        aria-label='open drawer'
        edge='start'
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Hidden smUp implementation='css'>
        <nav className={classes.drawer} aria-label='mailbox folders'>
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Hidden>
      <main className={classes.content}>
        <Hidden xsDown implementation='css'>
          {desktopHeader}
        </Hidden>

        <Switch>
          <MainRoutes setLoginModalOpen={setLoginModalOpen} />
          <UserRoutes />
          <AdminRoutes />
        </Switch>
      </main>
      <LoginModal
        setRegisterModalOpen={setRegisterModalOpen}
        setLoginModalOpen={setLoginModalOpen}
        loginModalOpen={loginModalOpen}
      />
      <RegisterModal
        setRegisterModalOpen={setRegisterModalOpen}
        setLoginModalOpen={setLoginModalOpen}
        resgisterModalOpen={resgisterModalOpen}
      />
      <NotificationModal />
    </div>
  );
}

export default ResponsiveDrawer;
