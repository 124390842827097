import React, { useState } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'

const useStyles = makeStyles({
  root: {
    // minHeight: '100vh',
    padding: 10,
    // justifyContent: 'center',
    // alignItems: 'center',
    // display: 'flex'
  },
})
export default function AddCourse() {
  const classes = useStyles()
  const history = useHistory()
  const [title, setTitile] = useState('')
  const [subtitle, setSubtitile] = useState('test')
  const [headlines, setHeadlines] = useState('test')
  const [prerequisites, setPrerequisites] = useState('test')
  const [discount, setDiscount] = useState('')
  const [price, setPrice] = useState('')
  const [description, setDescription] = useState('test')
  const [demolink, setDemolink] = useState('test')
  const [slug, setSlug] = useState('')
  const [image, setImage] = useState('')
  const addCourse = () => {
    let status
    let formData = new FormData()
    formData.append('title', title)
    formData.append('subtitle', subtitle)
    formData.append('headlines', headlines)
    formData.append('prerequisites', prerequisites)
    formData.append('discount', discount)
    formData.append('price', price)
    formData.append('description', description)
    formData.append('demo_link', demolink)
    formData.append('slug', slug)
    formData.append('course_image', image)
    fetch('https://api.backenddeveloper.ir/api/v1/admin/courses/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.token_type + ' ' + localStorage.token,
      },
      body: formData,
    })
      .then((response) => {
        status = response.status
        return response.json()
      })
      .then((responseJson) => {
        if (status === 201) {
          history.push('/admin-panel/courses')
        } else if (status === 401) {
          localStorage.clear()
          window.location.reload()
        }
      })
  }
  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Grid item lg={4} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='عنوان'
            style={{ width: '100%' }}
            variant='outlined'
            value={title}
            onChange={(e) => setTitile(e.target.value)}
          />
        </Grid>
        <Grid item lg={4} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='slug'
            style={{ width: '100%' }}
            variant='outlined'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </Grid>
        <Grid item lg={2} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='قیمت'
            style={{ width: '100%' }}
            variant='outlined'
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>
        <Grid item lg={2} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='میزان تخفیف'
            style={{ width: '100%' }}
            variant='outlined'
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
        </Grid>

        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='زیر عنوان'
            style={{ width: '100%' }}
            variant='outlined'
            value={subtitle}
            onChange={(e) => setSubtitile(e.target.value)}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='demo_link'
            style={{ width: '100%' }}
            variant='outlined'
            value={demolink}
            onChange={(e) => setDemolink(e.target.value)}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='پیش نیاز ها با :: از هم جدا شوند'
            style={{ width: '100%' }}
            variant='outlined'
            value={prerequisites}
            onChange={(e) => setPrerequisites(e.target.value)}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='مباحثی که در دوره گفته می شود با :: از هم جدا شوند'
            style={{ width: '100%' }}
            variant='outlined'
            value={headlines}
            onChange={(e) => setHeadlines(e.target.value)}
            multiline
            rows={4}
          />
        </Grid>
        <Grid
          item
          lg={12}
          style={{
            padding: 5,
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button component='label' variant='outlined' color='primary'>
            انتخاب تصویر دوره
            <input
              onChange={(e) => setImage(e.target.files[0])}
              type='file'
              style={{ display: 'none' }}
            />
          </Button>
          <span style={{ margin: 10 }}>{image ? image.name : ''}</span>
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <Editor
            apiKey='7tg1hqhe4hazzs0r7a2s98ipminudkt0q0dqj7uwre2o8e20'
            init={{
              plugins:
                'link image directionality image autoresize codesample code textcolor lists advlist layer media',
              menubar: 'insert tools',
              codesample_languages: [
                { text: 'HTML/XML', value: 'markup' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'CSS', value: 'css' },
                { text: 'Python', value: 'python' },
              ],
              toolbar:
                'undo redo | bold italic | alignleft aligncenter alignright alignjustify | ltr rtl | image | codesample | code | forecolor backcolor | numlist bullist | link | media',
              image_caption: true,
              image_advtab: true,
            }}
            onEditorChange={(content, editor) => setDescription(content)}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <Button
            onClick={addCourse}
            fullWidth
            variant='outlined'
            color='primary'
          >
            ثبت دوره
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
