import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Title from '../../components/title'
import { getTranslate } from '../../localization/index'
import Skill from '../../components/skill'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import MyStepper from '../../components/MyStepper'
import Teacher from '../../components/Teacher'
import ProfileImage from '../../assets/images/proile.webp'
import { Helmet } from 'react-helmet'
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    skillGrid: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }

    },
    skillSecondTitle: {
        marginLeft: 10
    },
    titr: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },

}))

export default function FarshidScreen() {
    const classes = useStyles()
    const translate = getTranslate()
    const educationalSteps = [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 1, date: "1397-1397", title: "کارشناسی ارشد", subtitle: "دانشگاه شهید بهشتی", content: "تحصیل در رشته مهندسی برق گرایش مخابرات - سیستم های مخابراتی ( انصراف به دلیل مشغله های کاری)" },
        { id: 1, date: "1395-1395", title: "کارشناسی ارشد", subtitle: "دانشگاه تهران", content: "تحصیل در رشته مهندسی برق گرایش مخابرات - سیستم های مخابراتی ( انصراف به دلیل مشغله های کاری)" },
        { id: 3, date: "1390-1394", title: "کارشناسی", subtitle: "دانشگاه اصفهان", content: "تحصیل در رشته مهندسی برق گرایش الکترونیک" },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ]
    const workingSteps = [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 1, date: "1397 - اکنون", title: "شرکت فنی مهندسی بامبو، فاتحان آینده", subtitle: "مدیرعامل و موسس شرکت", content: "انجام پروژه برنامه نویسی، طراحی وب سایت، طراحی اپلیکیشن های موبایل و اینترنت اشیا" },
        { id: 2, date: "1394 - اکنون", title: "موسسه آموزش آنلاین آسان یادبگیر", subtitle: "هم بنیان گذار و مدرس مجموعه", content: "تولید آموزش های ویدئویی و آنلاین از مباحث روز دنیای برنامه نویسی فرانت اند، بک اند، اپلیکیشن موبایل و بازی سازی" },
        { id: 3, date: "1398 - اکنون", title: "پروژه حقوق بین الملل لگامارت", subtitle: "طراحی وب سایت کارهای حقوقی بین المللی Legamart", content: "فرانت اند  react js - بک اند Laravel" },
        { id: 4, date: "1398 - اکنون", title: "مجتمع فنی تهران شعبه سعادت آباد", subtitle: "مدرس دوره های آموزشی مجتمع", content: "مدرس دوره های آموزش React.js مجتمع فنی تهران شعبه اصلی" },
        { id: 5, date: "1398 - 1398", title: "شرکت مهندسی ساعی بنیان", subtitle: "طراحی اپلیکیشن خانه هوشمند Remonex", content: "طراحی اپلیکیشن با React Native جهت کنترل وسایل خانه توسط سخت افزار طراحی شده" },
        { id: 6, date: "1398 - اکنون", title: "www.ReactNativeDeveloper.ir", subtitle: "طراح، مدیر و مدرس وب سایت", content: "فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی طراحی اپلیکیشن موبایل اندروید و آی او اس با React Native" },
        { id: 7, date: "1398 - اکنون", title: "www.ReactDeveloper.ir", subtitle: "طراح، مدیر و مدرس وب سایت", content: "فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی فرانت اند با React js" },
        { id: 8, date: "1398 - اکنون", title: "www.BackendDeveloper.ir", subtitle: "طراح، مدیر و مدرس وب سایت", content: "فرانت اند React js - بک اند با لاراول - تولید دوره های آموزش بک اند نود جی اس و لاراول" },
        { id: 9, date: "1398 - اکنون", title: "www.FlutterLearning.ir", subtitle: "طراح، مدیر و مدرس وب سایت", content: "فرانت اند React js - بک اند با لاراول - تولید دوره های آموزش طراحی اپلیکیشن موبایل اندروید و آی او اس با زبان دارت و فریمورک فلاتر" },
        { id: 10, date: "1398 - اکنون", title: "www.AiLearning.ir", subtitle: "طراح، مدیر و مدرس وب سایت", content: "فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی پردازش تصویر و هوش مصنوعی" },
        { id: 11, date: "1397 - 1397", title: "مجوعه آموزش فرادرس", subtitle: "مدرس دوره آموزشی", content: "تولید دوره آموزش ویدئویی React Native" },
        { id: 12, date: "1397 - 1397", title: "مجموعه آموزشی دانشجویار", subtitle: "مدرس دوره آموزشی", content: "مدرس دوره جامع آموزش ساخت اپلیکیشن اینستاگرام با React Native" },
        { id: 13, date: "1393 - 1394", title: "دانشگاه رازی کرمانشاه", subtitle: "مدرس دوره های برنامه نویسی", content: "مدرس دوره آموزش طراحی وب سایت و ساخت اپلیکیشن اندروید با جاوا" },
        { id: 14, date: "1397 - 1397", title: "www.farshidkarami.ir", subtitle: "وب سایت شخصی", content: "فرانت اند React js" },
        { id: 15, date: "1397 - 1397", title: "www.asbambo.ir", subtitle: "وب سایت شرکتی", content: "فرانت اند React js" },
        { id: 16, date: "1398 - اکنون", title: "TApanel", subtitle: "همکاری در طراحی وب سایت مدیریت آژانس های مسافرتی استفاده شده در علی بابا، اسنپ تریپ و پرسپولیس", content: "فرانت اند با React js" },
        { id: 17, date: "1397 - 1397", title: "ستاره ساز", subtitle: "همکاری در طراحی اپلیکیشن مسابقه تلویزیونی استعدادیابی فوتبال ستاره ساز", content: "اپلیکیشن با React Native - بک اند Node.js" },
        { id: 18, date: "1397 - 1397", title: "هیپوتریپ", subtitle: "همکاری در طراحی وب سایت و اپلیکیشن رزرو بلیط هیپو تریپ", content: "اپلیکیشن موبایل با React Native" },
        { id: 19, date: "1397 - 1397", title: "طراحی اپلیکیشن سرگرمی دنگ دنگ", subtitle: "منتشر شده در کافه بازار", content: "طراحی اپلیکیشن با React Native" },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ]



    return (
        <Grid alignItems="flex-start" container className={classes.root} >
            <Helmet>
                <title>مدرس : فرشید کرمی سرینی</title>
                <meta name="description" content=" وب سایت BackEndDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی کد نویسی سمت سرور می باشد و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>

            <Grid container style={{ marginTop: 30 }}>
                <Grid item xs={12} md={12} style={{ padding: 15 }}  >
                    <Teacher
                        image={ProfileImage}
                        name="مهندس فرشید کرمی سرینی"
                        teacherProps={[
                            "مدیرعامل شرکت مهندسی بامبو، فاتحان آینده",
                            "هم بنیان گذار موسسه آموزش آنلاین آسان یادبگیر",
                            "دانشجوی ارشد مخابرات دانشگاه شهید بهشتی",
                            "مدرس در مجمتمع فنی تهران",
                            "طراح چندین وب سایت و اپلیکیشن معتبر"
                        ]}
                        teacherProps2={[
                            "مسلط به طراحی فرانت اند وب سایت ها با  React js",
                            "مسلط به کد نویسی سمت سرور / بک اند با زبان PHP و فریمورک Laravel",
                            "مسلط به کد نویسی سمت سرور / بک اند با زبان جاوا اسکریپت / Node.js",
                            "مسلط به طراحی اپلیکیشن موبایل Android  و IOS با زبان جاوااسکریپت / React Native",
                            "مسلط به طراحی و پیاده سازی پایگاه های داده SQL و محبوب ترین آن ها  MySQL",
                            "مسلط به طراحی و پیاده سازی پایگاه های داده NoSql و محبوب ترین آن ها MongoDB",
                            "مسلط به راه اندازی و نگهداری سرور های لینوکسی",
                            "مسلط به مباحث هوش مصنوعی / پردازش تصویر / شبکه های عصبی / یادگیری عمیق با زبان پایتون"
                        ]}
                        mobile="09220479451"
                        onClick={() => { }}
                    />
                </Grid>
            </Grid>

            <Grid className={classes.skillGrid} item container xs={12}>
                <Title title={translate.myskills} />
                <Grid container direction='row' >
                    <Grid item xs={12} md={6}  >
                        <Skill value={90} title="Node.js" />
                        <Skill value={95} title="React js" />
                        <Skill value={95} title="React Native" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Skill value={90} title="PHP & Laravel" />
                        <Skill value={90} title="MySQL & MongoDB" />
                        <Skill value={85} title="هوش مصنوعی" />

                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.skillGrid} item container xs={12}>
                <Title title={translate.resume} />
                <Grid container direction='row' justify="flex-start" alignItems="center"  >
                    <LocalLibraryIcon style={{ fontSize: 34 }} />
                    <Typography className={classes.skillSecondTitle} variant="h4">
                        {translate.educationalqualifications}
                    </Typography>
                </Grid>
                <Grid container style={{ marginTop: 15, marginBottom: 15 }} >
                    <MyStepper steps={educationalSteps} />
                </Grid>
                <Grid container direction='row' justify="flex-start" alignItems="center"  >
                    <BusinessCenterIcon style={{ fontSize: 34 }} />
                    <Typography className={classes.skillSecondTitle} variant="h4">
                        {translate.workingexperience}
                    </Typography>
                </Grid>
                <Grid container style={{ marginTop: 15, marginBottom: 15 }} >
                    <MyStepper steps={workingSteps} />
                </Grid>
                <Typography style={{ marginTop: 15 }} variant="subtitle2">
                    <span className={classes.titr} >توجه: </span>
                    تنها پروژه ها و نمونه کارهای مرتبط و مربوط به سال های اخیر در رزومه آمده است. و مدرس دارای تجربه های کاری بیشتر می باشد.
                </Typography>

            </Grid>

        </Grid>
    )
}
