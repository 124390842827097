import React, { useState, useEffect } from 'react'
import {
  Container,
  TextField,
  Grid,
  Button,
  Slide,
  Modal,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import { validateEmail } from '../config/Utils'
import Apis from '../config/Apis'
import SuccessMessage from '../components/SuccessMessage'
import ErrorMessage from '../components/ErrorMessage'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 80,
    padding: 20,
    paddingBottom: 20,
    backgroundColor: '#FFF',
    borderRadius: 10,
    position: 'relative',
    boxShadow: `0px 0px 20px  5px ${theme.palette.primary.main}`,
    width: 400,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      padding: 5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  iconSpan: {
    width: 80,
    height: 80,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    borderRadius: 50,
    top: -50,
  },
  icon: {
    fontSize: 70,
    color: '#FFF',
  },
  spinnerBox: {
    width: 0,
    height: 0,
    overflow: 'Hidden',
    transition: 'all 0.3s ease-in-out',
  },
  spinnerBoxVisible: {
    width: 35,
    height: 20,
  },
}))
export default function LoginModal({
  loginModalOpen,
  setRegisterModalOpen,
  setLoginModalOpen,
}) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [regiterButtonDisabled, setRegisterButtonDisabled] = useState(true)
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false)
  const [openErrorMessage, setOpenErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (validateEmail(email) && password.length > 5) {
      setRegisterButtonDisabled(false)
    } else {
      setRegisterButtonDisabled(true)
    }
  }, [email, password])
  const login = () => {
    setLoading(true)
    let formData = {
      email,
      password,
    }
    let status
    fetch(Apis.login, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        status = response.status
        return response.json()
      })
      .then((responseJson) => {
        setLoading(false)
        if (status === 400) {
          for (const key in responseJson.errors) {
            if (responseJson.errors.hasOwnProperty(key)) {
              switch (key) {
                case 'email':
                  setEmailError(responseJson.errors[key][0])
                  break
                case 'password':
                  setPasswordError(responseJson.errors[key][0])
                  break
                default:
                  break
              }
            }
          }
        } else if (status === 200) {
          console.log(responseJson)

          localStorage.token = responseJson.token
          localStorage.token_type = responseJson.token_type
          localStorage.role = responseJson.role
          setOpenSuccessMessage(true)
          setTimeout(() => {
            window.location.reload()
          }, 1)
        } else if (status === 401) {
          setEmailError('کاربری با این مشخصات یافت نشد')
        } else {
          setOpenErrorMessage(true)
        }
      })
  }

  return (
    <>
      <Modal open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
        <Slide direction='up' in={loginModalOpen} mountOnEnter unmountOnExit>
          <Container className={classes.root}>
            <Grid
              item
              container
              direction='column'
              justify='center'
              alignItems='center'
              xs={12}
            >
              <span className={classes.iconSpan}>
                <PersonIcon className={classes.icon} />
              </span>
              <TextField
                style={{ width: '85%', marginTop: 40 }}
                required
                label='ایمیل'
                variant='outlined'
                inputProps={{
                  className: 'blackinput',
                  onFocus: () => {
                    setEmailError('')
                  },
                  onBlur: () => {
                    validateEmail(email)
                      ? setEmailError('')
                      : setEmailError('فرمت ایمیل وارد شده صحیح نمی باشد')
                  },
                }}
                error={emailError.length > 0}
                helperText={emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                style={{ width: '85%', marginTop: 15 }}
                required
                type='password'
                label='رمز عبور'
                variant='outlined'
                inputProps={{
                  className: 'blackinput',
                  onFocus: () => {
                    setPasswordError('')
                  },
                  onBlur: () => {
                    password.length < 6
                      ? setPasswordError('رمز عبور باید حداقل 6 کاراکتر باشد')
                      : setPasswordError('')
                  },
                }}
                error={passwordError.length > 0}
                helperText={passwordError}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                onClick={login}
                disabled={regiterButtonDisabled || loading}
                size='large'
                style={{ width: '85%', marginTop: 25 }}
                variant='contained'
                color='primary'
              >
                ورود
                <div
                  className={[
                    classes.spinnerBox,
                    loading ? classes.spinnerBoxVisible : '',
                  ].join(' ')}
                >
                  {' '}
                  &nbsp;&nbsp; <CircularProgress size={20} />
                </div>
              </Button>
              <Button
                onClick={() => {
                  setLoginModalOpen(false, setRegisterModalOpen(true))
                }}
                size='large'
                style={{
                  width: '85%',
                  marginTop: 10,
                  justifyContent: 'flex-start',
                }}
                variant='text'
                color='primary'
              >
                ثبت نام نکرده ام
              </Button>
            </Grid>
          </Container>
        </Slide>
      </Modal>
      <SuccessMessage
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
        message=' ورود شما با موفقیت انجام شد'
      />
      <ErrorMessage
        open={openErrorMessage}
        setOpen={setOpenErrorMessage}
        message='خطایی در ارتباط با سرور رخ داده است. دوباره تلاش کنید'
      />
    </>
  )
}
