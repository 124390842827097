import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#191d2b"
    }
})
export default function Discounts() {
    const classes = useStyles()
    const [discounts, setDiscounts] = useState([])
    const [type, setType] = useState(0)
    const [text, setText] = useState('')
    const [discount, setDiscount] = useState('')
    const [updateId, setUpdatedId] = useState(0)
    useEffect(() => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/discounts', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setDiscounts(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/discounts/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setDiscounts(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }
    const onActive = (id) => {
        let status
        fetch('https://api.backenddeveloper.ir/api/v1/admin/discounts/active', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setDiscounts(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }


    const addProccess = () => {
        const url = type === 0 ? "https://api.backenddeveloper.ir/api/v1/admin/discounts/add" : "https://api.backenddeveloper.ir/api/v1/admin/discounts/update"
        let data = type === 0 ? {
            status: 1,
            text: text,
            discount: parseInt(discount),
        } : {
                status: 1,
                text: text,
                discount: parseInt(discount),
                id: updateId
            }
        let status
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': "application/json"
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 201) {
                    setDiscounts(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    return (
        <Grid container className={classes.root} >
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <TextField
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    variant="outlined" fullWidth
                    label="کد تخفیف"
                />

            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <TextField
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    variant="outlined" fullWidth
                    label="درصد تخفیف"
                />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 25, padding: 10 }} >
                <Button style={{ height: 55 }} color="primary" variant="outlined" fullWidth onClick={addProccess} >
                    اضافه / ویرایش
                </Button>
            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>کد تخفیف</TableCell>
                            <TableCell align="left">درصد</TableCell>
                            <TableCell align="left">ویرایش</TableCell>
                            <TableCell align="left">غیر فعال کردن</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discounts.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >
                                    {row.text}
                                </TableCell>
                                <TableCell align="left">{row.discount} % </TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => {
                                            setUpdatedId(row.id)
                                            setType(1)
                                            setDiscount(row.discount)
                                            setText(row.text)
                                        }}
                                        variant="text" color="primary" >
                                        ویرایش
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    {row.status === 0 ?
                                        <Button onClick={() => onActive(row.id)} variant="text" color="primary" >
                                            فعال کردن
                                        </Button>
                                        : (
                                            <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                                غیر فعال کردن
                                        </Button>
                                        )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
